<template>
  <div class="center">
    <img src="../assets/404.gif" alt="" />
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.center {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
img {
  width: 300px;
}
</style>
