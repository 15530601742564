<template>
  <div calss="box">
    <!-- 首页上半部分 -->
    <el-row type="flex" class="row-bg" justify="space-between">
      <div class="block marr10">
        <el-carousel height="450px" arrow="always" :interval="3000">
          <el-carousel-item v-for="(item, index) in imgList" :key="index">
            <div @click="gonew(item.id)" class="cursor" style="width:590px; height:450px">
              <img :src="'http://www.data.tianlangsoft.com/' + item.picturePath" alt="" style="width:100%; height:90%" />
              <!-- <img src="http://192.168.3.200:9600/images\4092670e-649d-466e-9d53-23b1aa2c5803.png" alt="" style="width:100%; height:90%" /> -->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <notice class="notice" />
    </el-row>
    <!-- 首页下半部分 -->
    <el-row type="flex" class="row-bg" justify="space-between">
      <work class="work" />
      <school class="school" />
      <other class="other" />
    </el-row>

    <!-- 定位fixed -->
    <div class="fixed1">
      <a href="#"><img src="../../assets/gzh.jpg" alt=""/></a>
      <!-- <img src="../../assets/gzh.jpg"></img> -->
      <!-- <img src="http://192.168.3.200:7070/images\\6bba089d-02fd-4f5b-9a32-ebb0f3be4002.png\"></img> -->
    </div>
    <div class="fixed2">
      <a href="#"><img src="../../assets/wb.jpg" alt=""/></a>
    </div>
  </div>
</template>
<script>
import notice from '../../views/notice.vue'
import work from '../../views/work.vue'
import school from '../../views/school.vue'
import other from '../../views/other.vue'

import { getNewsList } from '../../api/api'

export default {
  components: { notice, work, school, other },
  name: 'index',
  data() {
    return {
      // imgList:[
      //   { path:'../../assets/gzh.jpg',index: 1},
      //   { path:'../../assets/gongZhongHao.jpg',index: 2},
      //   { path:'../../assets/bg.png',index: 3},
      // ],
      imgList: [],
      // imglength: 3
    }
  },
  created() {
    this.getnews()
  },
  methods: {
    getnews() {
      const data = {
        current: 1,
        newsCategoryId: 45,
        size: 3
        // size: 3
      }
      getNewsList(data)
        .then(res => {
          console.log(res)
          if (res.code == 200) {
            this.imgList = res.data.records
            // this.imglength = this.imgList.length + 1
            // console.log(res.data.records[0].picturePath);
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    gonew(id) {
      this.$router.push({ path: '/home/news', query: { id: id } })
    }
  }
}
</script>
<style lang="less" scoped>
.block {
  width: 50%;
}
.block div {
  img {
    width: 100%;
    height: 100%;
    // object-fit:cover;
  }
}
.notice {
  width: 50%;
}
.work {
  width: 40%;
  margin-right: 10px;
}
.school {
  width: 40%;
}
.other {
  width: 20%;
  margin-left: 5px;
}
.fixed1 {
  position: fixed;
  top: 270px;
  right: 2px;
}
.fixed2 {
  position: fixed;
  top: 340px;
  right: 2px;
}
</style>
