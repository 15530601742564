import { render, staticRenderFns } from "./work.vue?vue&type=template&id=3614921d&scoped=true"
import script from "./work.vue?vue&type=script&lang=js"
export * from "./work.vue?vue&type=script&lang=js"
import style0 from "./work.vue?vue&type=style&index=0&id=3614921d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3614921d",
  null
  
)

export default component.exports