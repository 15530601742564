// 对axios的封装
import axios from 'axios'
// eslint-disable-next-line camelcase
// let is_baseURL = 'http://192.168.3.200:7070' // 测试环境
// let is_baseURL = 'http://192.168.3.200:9600/api' // 开发环境www.data.tianlangsoft.com
let is_baseURL = 'http://www.data.tianlangsoft.com/api' // 正式环境www.data.tianlangsoft.com
const token = sessionStorage.getItem('token')
// 创建axios实例
const request = axios.create({
  baseURL: is_baseURL,
  headers: { 
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Bearer ' + token
   }
})
// response服务器响应拦截器
request.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    console.log('err', error)
  }
)

export default request


// // 对axios的封装
// import axios from 'axios'

// let is_baseURL = 'http://192.168.3.200:9600/api' // 测试环境

// const request = axios.create({
//   baseURL: is_baseURL,
//   headers: { 
//     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
//   }
// })

// // request拦截器
// // request.interceptors.request.use(config => {
// //   console.log('请求体打印',config)
// //   const token = sessionStorage.getItem('token')
// //   if (token) {
// //     config.headers.Authorization = token
// //   }
// //   console.log('请求体打印2222',config)
// //   return config
// // }, error => {
// //   return Promise.reject(error)
// // })

// // response拦截器
// request.interceptors.response.use(
//   response => {
//     const res = response.data
//     return res
//   },
//   error => {
//     console.log('err', error)
//     return Promise.reject(error)
//   }
// )

// export default request