<template>
  <div>
    <globalTitle />
    <div>
      <img src="../../assets/keyanlixiangjieti.png" alt="" />
    </div>
  </div>
</template>

<script>
import globalTitle from '../globalTitle.vue'
export default {
  components: { globalTitle },
  name: 'banShiZhiNan'
}
</script>

<style lang="less" scoped></style>
