<template>
  <div>
    <globalTitle />
  </div>
</template>

<script>
import globalTitle from '../globalTitle.vue'
export default {
  components: { globalTitle },
  name: 'xueXiaoShouYe'
}
</script>

<style lang="less" scoped></style>
